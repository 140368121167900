<template>
    <section class="container" :id="block.ancre" :class="'bg-' + block.couleurDeLarrierePlan.value">
        <div class="bloc-text" data-inview="fadeIn" data-delay="200">
            <h3 class="title small">{{ block.titre }}</h3>
            <span v-html="block.texte"></span>
        </div>
    </section>
</template>

<script>
export default {
    name: 'contenu',

    props: {
        block: {
            type: Object,
            required: true,
        },
    },

    mounted() {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
