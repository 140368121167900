<template>
    <div class="page-wrapper body-padding-bottom" v-if="contentIsLoaded">
        <section class="hero-experience">
            <div class="left">
                <h1 class="title">{{ content.titreDeLaNavigation }}</h1>
                <ul class="sub-menu">
                    <a
                        :href="'#' + item.ancre"
                        v-scroll-to:0
                        v-for="(item, i) in content.liensAncres"
                        :key="i"
                    >
                        <li>
                            <p class="title">{{ item.titre }}</p>
                            <img
                                src="@/assets/img/arrow-down-orange.svg"
                                class="arrow"
                                alt="Arrow down"
                            />
                        </li>
                    </a>
                </ul>
            </div>
            <div class="right">
                <img src="@/assets/img/sceau.svg" class="sceau" alt="Sceau Cigale" />
                <img src="@/assets/img/fleuve.png" class="fleuve" alt="Fleuve Cigale" />
            </div>
        </section>

        <!-- Matrix Blocks -->
        <div
            v-for="(block, i) in content.blocks"
            :key="i"
            :class="'block-container block-' + block.handle"
        >
            <component :is="block.handle" :block="block"></component>
        </div>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapState, mapGetters } from 'vuex'

import imageTexte from '@/components/contentBlocks/imageTexte.vue'
import imageSimple from '@/components/contentBlocks/imageSimple.vue'
import contenu from '@/components/contentBlocks/contenu.vue'
import faq from '@/components/contentBlocks/faq.vue'
import tableaux from '@/components/contentBlocks/tableaux.vue'
import tableau100 from '@/components/contentBlocks/tableau100.vue'

export default {
    components: {
        imageTexte,
        imageSimple,
        contenu,
        faq,
        tableaux,
        tableau100,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    data() {
        return {
            flickityOptions: {
                prevNextButtons: true,
                pageDots: false,
                contain: true,
                autoPlay: true,
                wrapAround: true,
                imagesLoaded: true,
            },
        }
    },
}
</script>

<style lang="scss" scoped></style>
