<template>
    <section class="container" :id="block.ancre" :class="'bg-' + block.couleurDeLarrierePlan.value">
        <div class="split-img-txt" :class="'img-' + block.pos">
            <div class="img-wrap" data-inview="revealLeft" data-delay="200">
                <div class="carousel carousel-item" ref="flickity">
                    <div class="carousel-cell" v-for="(img, i) in block.images" :key="i + 'im'">
                        <img :src="img.url" :alt="img.titre" />
                    </div>
                </div>
            </div>
            <div class="content" data-inview="fadeInUp" data-delay="400">
                <h3 class="title small"><span v-html="block.titre"></span></h3>
                <p class="text">
                    <span v-html="block.textes"></span>
                </p>
                <a :href="block.boutonUrl" target="_blank" class="button-cta dark" v-if="block.boutonUrl && block.bouton.customText">
                    <p class="title">{{ block.bouton.customText }}</p>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import Flickity from 'flickity'

export default {
    name: 'imageTexte',

    props: {
        block: {
            type: Object,
            required: true,
        },
    },

    mounted() {
        setTimeout(() => {
            [].slice.call(document.querySelectorAll('.carousel-item')).forEach(el => {
                const nbImgs = [].slice.call(el.querySelectorAll('.carousel-cell'))
                new Flickity(el, {
                    prevNextButtons: nbImgs.length >= 2,
                    pageDots: false,
                    contain: true,
                    autoPlay: true,
                    wrapAround: true,
                    imagesLoaded: true,
                })
            })
        }, 555)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
