<template>
    <section class="container" :id="block.ancre" :class="'bg-' + block.couleurDeLarrierePlan.value">
        <div class="bloc-faq" data-inview="fadeIn" data-delay="200">
            <h3 class="title small">{{ block.titre }}</h3>

            <div
                class="question"
                :class="{ actif: actif.includes(i) }"
                v-for="(item, i) in block.items"
                :key="i + 'item'"
            >
                <div class="top" @click.prevent="activate(i)">
                    <p class="text">{{ item.titre }}</p>
                    <div class="plus">
                        <div class="v-line"></div>
                        <div class="h-line"></div>
                    </div>
                </div>
                <div class="bottom">
                    <p class="text">
                        <span v-html="item.texte"></span>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'faq',

    props: {
        block: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            actif: [],
        }
    },

    mounted() {},

    methods: {
        activate(i) {
            if (this.actif.includes(i)) {
                this.actif = []
                return
            }

            this.actif = []
            this.actif.push(i)
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
