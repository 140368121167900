<template>
    <section class="container" :id="block.ancre" :class="'bg-' + block.couleurDeLarrierePlan.value">
        <div class="bloc-table">
            <div class="full" data-inview="fadeIn" data-delay="200">
                <h3 class="title small">{{ block.titre }}</h3>
                <p class="text">{{ block.texte }}</p>

                <div class="table">
                    <div class="row" v-for="(item, i) in block.tableau" :key="i + 'tabl'">
                        <div class="cell">
                            <p class="text">{{ item.gauche }}</p>
                        </div>
                        <div class="cell">
                            <p class="text">{{ item.droite }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'tableau100',

    props: {
        block: {
            type: Object,
            required: true,
        },
    },

    mounted() {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
