<template>
    <section class="container" :id="block.ancre" :class="'bg-' + block.couleurDeLarrierePlan.value">
        <div class="bloc-img" data-inview="revealLeft" data-delay="200">
            <div class="overlay"></div>
            <img :src="block.image.url" class="video-img" :alt="block.image.titre" />
        </div>
    </section>
</template>

<script>
export default {
    name: 'imageSimple',

    props: {
        block: {
            type: Object,
            required: true,
        },
    },

    mounted() {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
